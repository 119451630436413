<template>
  <div class="page">
    <div class="formcontainer staticpage">
      <div class="formbox">
        <h2 style="margin-top: -50px; text-align: center">
          TÜRKİYE CUMHURİYETİ TİCARET BAKANLIĞI <br />
          İLETİ YÖNETİM SİSTEMİ HİZMET SAĞLAYICILARA YÖNELİK AYDINLATMA
        </h2>

        <div class="descline">
          <div class="desctext" style="width: 100%">
            <div class="Section0" style="layout-grid: 18pt">
              <p
                class="MsoNormal"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                "
              >
                <span style="mso-spacerun: 'yes'"
                  >Ki&#351;isel verileriniz bizim i&#231;in
                  &#246;nemlidir!</span
                ><span style="mso-spacerun: 'yes'"> </span>
              </p>
              <p
                class="MsoNormal"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                "
              >
                <span style="mso-spacerun: 'yes'"
                  >Ki&#351;isel verilerin i&#351;lenmesinde ba&#351;ta &#246;zel
                  hayat&#305;n gizlili&#287;i olmak &#252;zere ki&#351;ilerin
                  temel hak ve &#246;zg&#252;rl&#252;klerinin
                  korunmas&#305;n&#305; ama&#231;layan 7 Nisan 2016 tarih ve
                  29677 Say&#305;l&#305; Resm&#238; Gazete’de yay&#305;mlanan
                  6698 say&#305;l&#305; Ki&#351;isel Verilerin Korunmas&#305;
                  Kanunu’nun (“</span
                ><b
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                    >KVKK</span
                  ></b
                ><span style="mso-spacerun: 'yes'">” veya “</span
                ><b
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                    >Kanun</span
                  ></b
                ><span style="mso-spacerun: 'yes'"
                  >”) “Veri Sorumlusunun Ayd&#305;nlatma
                  Y&#252;k&#252;ml&#252;l&#252;&#287;&#252;”
                  ba&#351;l&#305;kl&#305; 10. maddesi ile 10 Mart 2018 tarih ve
                  30356 say&#305;l&#305; Resmi Gazete’de yay&#305;mlanan
                  Ayd&#305;nlatma
                  Y&#252;k&#252;ml&#252;l&#252;&#287;&#252;n&#252;n Yerine
                  Getirilmesinde Uyulacak Usul ve Esaslar Hakk&#305;nda
                  Tebli&#287; uyar&#305;nca, i&#351;bu Ayd&#305;nlatma Metni ile
                  T&#252;rkiye Cumhuriyeti Ticaret Bakanl&#305;&#287;&#305;
                  (“</span
                ><b
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                    >Ticaret Bakanl&#305;&#287;&#305;</span
                  ></b
                ><span style="mso-spacerun: 'yes'"
                  >”) taraf&#305;ndan &#304;leti Y&#246;netim Sistemi
                  (“&#304;leti Y&#246;netim Sistemi Web”, e-Devlet entegrasyonu)
                  ileti&#351;im kanallar&#305; arac&#305;l&#305;&#287;&#305;yla
                  i&#351;lenen ki&#351;isel verileriniz hakk&#305;nda sizi
                  bilgilendirmeyi hedeflemekteyiz.</span
                ><span style="mso-spacerun: 'yes'"> </span>
              </p>
              <p
                class="MsoNormal"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  mso-outline-level: 2;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                "
              >
                <b
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                    >VER&#304; SORUMLUSU</span
                  ></b
                ><b
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                  >
                  </span
                ></b>
              </p>
              <p
                class="MsoNormal"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                "
              >
                <span style="mso-spacerun: 'yes'"
                  >6563 say&#305;l&#305; Elektronik Ticaretin D&#252;zenlenmesi
                  Hakk&#305;nda Kanun ile Ticari &#304;leti&#351;im ve Ticari
                  Elektronik &#304;letiler Hakk&#305;nda Y&#246;netmelik
                  uyar&#305;nca Ticaret Bakanl&#305;&#287;&#305; taraf&#305;ndan
                  ulusal bir ticari elektronik ileti y&#246;netim sisteminin
                  kurulmas&#305;na karar verilmi&#351; ve T&#252;rkiye Odalar ve
                  Borsalar Birli&#287;i konuyla ilgili
                  g&#246;revlendirilmi&#351;tir. &#304;leti Y&#246;netim Sistemi
                  Anonim &#350;irketi (“</span
                ><b
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                    >&#350;irket</span
                  ></b
                ><span style="mso-spacerun: 'yes'"
                  >”), T&#252;rkiye Odalar ve Borsalar Birli&#287;i
                  taraf&#305;ndan s&#246;z konusu ama&#231; i&#231;in
                  T&#252;rkiye Odalar ve Borsalar Birli&#287;i E&#287;itim ve
                  K&#252;lt&#252;r Vakf&#305; &#304;&#351;tiraki olarak
                  kurulmu&#351;tur.</span
                ><span style="mso-spacerun: 'yes'"> </span>
              </p>
              <p
                class="MsoNormal"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                "
              >
                <span style="mso-spacerun: 'yes'"
                  >&#350;irket, kurmu&#351; oldu&#287;u ticari elektronik ileti
                  y&#246;netim sistemi (“</span
                ><b
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                    >&#304;YS</span
                  ></b
                ><span style="mso-spacerun: 'yes'"
                  >”) arac&#305;l&#305;&#287;&#305;yla, elektronik ticaret
                  faaliyetinde bulunan ger&#231;ek ya da t&#252;zel ki&#351;i
                  hizmet sa&#287;lay&#305;c&#305;lar&#305;n arama, mesaj,
                  e-posta gibi farkl&#305; tipteki ticari elektronik ileti
                  onaylar&#305;n&#305; saklay&#305;p y&#246;netebilecekleri ve
                  ticari elektronik ileti al&#305;c&#305;lar&#305;n&#305;n da
                  ticari elektronik ileti onay&#305; almas&#305;na, reddetme
                  hakk&#305;n&#305; kullanmas&#305;na ve &#351;ik&#226;yet
                  s&#252;re&#231;lerini y&#246;netmesine imk&#226;n tan&#305;yan
                  ve t&#252;m onaylar&#305;n zaman damgas&#305; ile kay&#305;t
                  alt&#305;na al&#305;nd&#305;&#287;&#305; ulusal veri
                  taban&#305; kurmu&#351;tur.</span
                ><span style="mso-spacerun: 'yes'"> </span>
              </p>
              <p
                class="MsoNormal"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                "
              >
                <span style="mso-spacerun: 'yes'"
                  >A&#231;&#305;klanan hizmetin yerine getirilmesi
                  esnas&#305;nda i&#351;lenen ki&#351;isel verileriniz
                  bak&#305;m&#305;ndan, Kanun uyar&#305;nca, Ticaret
                  Bakanl&#305;&#287;&#305;, “Veri Sorumlusu”
                  s&#305;fat&#305;n&#305; haiz olup Veri Sorumlusuna
                  a&#351;a&#287;&#305;da yer verilen ileti&#351;im bilgileri
                  arac&#305;l&#305;&#287;&#305;yla ula&#351;man&#305;z
                  m&#252;mk&#252;nd&#252;r.</span
                ><span style="mso-spacerun: 'yes'"> </span>
              </p>
              <p
                class="MsoNormal"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                "
              >
                <b
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                    >Merkez Adres:
                  </span></b
                ><span style="mso-spacerun: 'yes'">&nbsp;</span
                ><span
                  style="
                    mso-spacerun: 'yes';

                    mso-fareast-font-family: Calibri;

                    background: rgb(255, 255, 255);
                    mso-shading: rgb(255, 255, 255);
                  "
                  >S&#246;&#287;&#252;t&#246;z&#252; Mah. 2176. Sk. No:63
                  06530&nbsp;&#199;ankaya / ANKARA</span
                ><span
                  style="
                    mso-spacerun: 'yes';

                    mso-fareast-font-family: Calibri;

                    background: rgb(255, 255, 255);
                    mso-shading: rgb(255, 255, 255);
                  "
                >
                </span>
              </p>
              <p
                class="MsoNormal"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                "
              >
                <b
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                    >Telefon:
                  </span></b
                ><span style="mso-spacerun: 'yes'">&nbsp;</span
                ><span
                  style="
                    mso-spacerun: 'yes';

                    mso-fareast-font-family: Calibri;

                    background: rgb(255, 255, 255);
                    mso-shading: rgb(255, 255, 255);
                  "
                  >+90 312 204 75 00</span
                ><span
                  style="
                    mso-spacerun: 'yes';

                    mso-fareast-font-family: Calibri;

                    background: rgb(255, 255, 255);
                    mso-shading: rgb(255, 255, 255);
                  "
                >
                </span>
              </p>
              <p
                class="MsoNormal"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                "
              >
                <b
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                    >&#304;nternet Adresi:
                  </span></b
                ><span style="mso-spacerun: 'yes'">&nbsp;</span
                ><span
                  ><a href="https://ticaret.gov.tr"
                    ><u
                      ><span
                        class="16"
                        style="
                          mso-spacerun: 'yes';

                          text-decoration: underline;
                          text-underline: single;
                        "
                        >https://ticaret.gov.tr</span
                      ></u
                    ></a
                  ></span
                ><span style="mso-spacerun: 'yes'"> </span>
              </p>
              <p class="MsoNormal">
                <b
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                    ><br
                      clear="all"
                      style="page-break-before: always" /></span></b
                ><b
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                  >
                  </span
                ></b>
              </p>
              <p
                class="46"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  margin-left: 36pt;
                  text-indent: -18pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                  mso-list: l13 level1 lfo1;
                "
              >
                <span style="font-weight: bold"
                  ><span style="mso-list: Ignore"
                    >1.<span>&nbsp;</span></span
                  ></span
                >
                <b style="mso-bidi-font-weight: normal"
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                    >&#304;&#351;lenen Ki&#351;isel Verileriniz, Toplanma
                    Y&#246;ntemleri, &#304;&#351;lenme Ama&#231;lar&#305; ve
                    Hukuka Uygunluk Sebepleri
                  </span></b
                ><b style="mso-bidi-font-weight: normal"
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                  >
                  </span
                ></b>
              </p>
              <p
                class="MsoNormal"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                "
              >
                <span
                  style="
                    mso-spacerun: 'yes';

                    mso-fareast-font-family: Calibri;
                  "
                  >A&#351;a&#287;&#305;daki Tablo-1’de belirtilen ki&#351;isel
                  verileriniz </span
                ><span
                  ><a href="https://iys.org.tr"
                    ><u
                      ><span
                        class="16"
                        style="
                          mso-spacerun: 'yes';

                          mso-fareast-font-family: Calibri;
                          color: rgb(5, 99, 193);
                          text-decoration: underline;
                          text-underline: single;
                        "
                        >https://iys.org.tr</span
                      ></u
                    ></a
                  ></span
                ><span
                  style="
                    mso-spacerun: 'yes';

                    mso-fareast-font-family: Calibri;
                  "
                  >&nbsp;ve e-devlet entegrasyonu &#252;zerinden toplanmakta
                  olup &#304;YS ba&#351;vurusu, &#304;YS giri&#351;i ile
                  vatanda&#351;lar&#305;n ileti onay ve red bildirimlerinin
                  y&#246;netimine ili&#351;kin olarak a&#351;a&#287;&#305;daki
                  Tablo-1’deki ama&#231;larla </span
                ><span
                  style="
                    mso-spacerun: 'yes';

                    mso-fareast-font-family: Calibri;
                    color: rgb(0, 0, 0);
                  "
                  >ba&#287;lant&#305;l&#305;, s&#305;n&#305;rl&#305; ve
                  &#246;l&#231;&#252;l&#252; bir &#351;ekilde ve KVKK’n&#305;n
                  ilkeleri do&#287;rultusunda KVK mevzuat&#305;na uygun olarak </span
                ><span
                  style="
                    mso-spacerun: 'yes';

                    mso-fareast-font-family: Calibri;
                  "
                  >otomatik ve otomatik olmayan yollarla elde edilmekte ve
                  i&#351;lenmektedir. </span
                ><span
                  style="
                    mso-spacerun: 'yes';

                    mso-fareast-font-family: Calibri;
                  "
                >
                </span>
              </p>
              <p
                class="MsoNormal"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                "
              >
                <b style="mso-bidi-font-weight: normal"
                  ><i style="mso-bidi-font-style: normal"
                    ><span
                      style="
                        mso-spacerun: 'yes';

                        font-weight: bold;
                        mso-ansi-font-style: italic;
                      "
                      >Tablo-1</span
                    ></i
                  ></b
                ><b style="mso-bidi-font-weight: normal"
                  ><i style="mso-bidi-font-style: normal"
                    ><span
                      style="
                        mso-spacerun: 'yes';

                        font-weight: bold;
                        mso-ansi-font-style: italic;
                      "
                    >
                    </span></i
                ></b>
              </p>
              <div style="overflow-x: auto">
                <table
                  class="MsoTableGrid"
                  border="1"
                  cellspacing="0"
                  style="
                    border-collapse: collapse;
                    border: none;
                    mso-border-left-alt: 0.5pt solid windowtext;
                    mso-border-top-alt: 0.5pt solid windowtext;
                    mso-border-right-alt: 0.5pt solid windowtext;
                    mso-border-bottom-alt: 0.5pt solid windowtext;
                    mso-border-insideh: 0.5pt solid windowtext;
                    mso-border-insidev: 0.5pt solid windowtext;
                    mso-padding-alt: 0pt 5.4pt 0pt 5.4pt;
                  "
                >
                  <tr>
                    <td
                      width="32.7600%"
                      valign="top"
                      style="
                        width: 32.76%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: 1pt solid windowtext;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                        background: rgb(216, 216, 216);
                      "
                    >
                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          text-align: left;
                          line-height: 114%;
                        "
                      >
                        <b style="mso-bidi-font-weight: normal"
                          ><span style="font-weight: bold"
                            >Ki&#351;isel Veriniz</span
                          ></b
                        ><b style="mso-bidi-font-weight: normal"
                          ><span style="font-weight: bold"> </span
                        ></b>
                      </p>
                    </td>
                    <td
                      width="31.2600%"
                      valign="top"
                      style="
                        width: 31.26%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: 1pt solid windowtext;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                        background: rgb(216, 216, 216);
                      "
                    >
                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          text-align: left;
                          line-height: 114%;
                        "
                      >
                        <b style="mso-bidi-font-weight: normal"
                          ><span style="font-weight: bold"
                            >Hukuka Uygunluk Sebebi</span
                          ></b
                        ><b style="mso-bidi-font-weight: normal"
                          ><span style="font-weight: bold"> </span
                        ></b>
                      </p>
                    </td>
                    <td
                      width="35.9400%"
                      valign="top"
                      style="
                        width: 35.94%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: 1pt solid windowtext;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                        background: rgb(216, 216, 216);
                      "
                    >
                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          text-align: left;
                          line-height: 114%;
                        "
                      >
                        <b style="mso-bidi-font-weight: normal"
                          ><span style="font-weight: bold"
                            >&#304;&#351;leme Amac&#305;m&#305;z</span
                          ></b
                        ><b style="mso-bidi-font-weight: normal"
                          ><span style="font-weight: bold"> </span
                        ></b>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="32.7600%"
                      valign="top"
                      style="
                        width: 32.76%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                      "
                    >
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.8pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l14 level1 lfo2;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span>Kimlik Bilgileriniz </span><span> </span>
                      </p>
                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: -2.2pt;
                          text-align: left;
                          line-height: 114%;
                        "
                      >
                        <span>(</span
                        ><i
                          ><span style="font-style: italic"
                            >MERS&#304;S numaras&#305; ve ticari unvan, VKN/TCKN
                            vergi dairesi, e-imza</span
                          ></i
                        ><span>) </span><span> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.8pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l14 level1 lfo2;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span>&#304;leti&#351;im Bilgileriniz </span
                        ><span> </span>
                      </p>
                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          text-align: left;
                          line-height: 114%;
                        "
                      >
                        <span>(</span
                        ><i
                          ><span style="font-style: italic"
                            >Ba&#287;l&#305; oldu&#287;unuz il, il&#231;e ve
                            ileti&#351;im adresi, sabit ileti&#351;im
                            numaras&#305;, internet sitesi</span
                          ></i
                        ><span>)</span><span> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.8pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l12 level1 lfo3;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span>Di&#287;er Bilgileriniz </span><span> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 29.95pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l2 level1 lfo4;
                        "
                      >
                        <span
                          style="
                          font-family: 'Courier New';
                          mso-fareast-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >o<span>&nbsp;</span></span
                          ></span
                        >
                        <span>(</span
                        ><i
                          ><span style="font-style: italic"
                            >Yetkili ki&#351;i ad, soyad, TCKN,
                          </span></i
                        ><i><span style="font-style: italic"> </span></i>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 29.95pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l2 level1 lfo4;
                        "
                      >
                        <span
                          style="
                          font-family: 'Courier New';
                          mso-fareast-
                          font-style: italic;
                          
                        "
                          ><span style="mso-list: Ignore"
                            >o<span>&nbsp;</span></span
                          ></span
                        >
                        <i
                          ><span style="font-style: italic"
                            >Yetkili ki&#351;i e-posta adresi, telefon,</span
                          ></i
                        ><i><span style="font-style: italic"> </span></i>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 29.95pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l2 level1 lfo4;
                        "
                      >
                        <span
                          style="
                          font-family: 'Courier New';
                          mso-fareast-
                          font-style: italic;
                          
                        "
                          ><span style="mso-list: Ignore"
                            >o<span>&nbsp;</span></span
                          ></span
                        >
                        <i
                          ><span style="font-style: italic"
                            >Marka tescil belgesinde yer alan ismi ve marka
                            tescil belgesi</span
                          ></i
                        ><span>)</span><span> </span>
                      </p>
                    </td>
                    <td
                      width="31.2600%"
                      valign="top"
                      style="
                        width: 31.26%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                      "
                    >
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.6pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l8 level1 lfo5;
                        "
                      >
                        <span style="font-family: Symbol; font-size: 12pt"
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span class="16">KVKK madde 5/2-a:</span
                        ><span class="16"> </span>
                      </p>
                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: -2.4pt;
                          text-align: left;
                          line-height: 114%;
                        "
                      >
                        <span class="16"
                          >Kanunlarda a&#231;&#305;k&#231;a
                          &#246;ng&#246;r&#252;lmesi (</span
                        ><i
                          ><span class="16" style="font-style: italic"
                            >6563 say&#305;l&#305; Elektronik Ticaretin
                            D&#252;zenlenmesi Hakk&#305;nda Kanun ve</span
                          ></i
                        ><span class="16"
                          ><span style="mso-spacerun: 'yes'">&nbsp;</span></span
                        ><i
                          ><span class="16" style="font-style: italic"
                            >Ticari &#304;leti&#351;im ve Ticari Elektronik
                            &#304;letiler Hakk&#305;nda Y&#246;netmelik</span
                          ></i
                        ><span class="16">),</span><span class="16"> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.6pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l8 level1 lfo5;
                        "
                      >
                        <span style="font-family: Symbol; font-size: 12pt"
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span class="16">KVKK madde 5/2-&#231;:</span
                        ><span class="16"> </span>
                      </p>
                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          text-align: left;
                          line-height: 114%;
                        "
                      >
                        <span class="16"
                          >Veri Sorumlusunun hukuki
                          y&#252;k&#252;ml&#252;l&#252;&#287;&#252;n&#252;
                          yerine getirebilmesi i&#231;in zorunlu
                          olmas&#305;</span
                        ><b style="mso-bidi-font-weight: normal"
                          ><span style="font-weight: bold"> </span
                        ></b>
                      </p>
                    </td>
                    <td
                      width="35.9400%"
                      valign="top"
                      style="
                        width: 35.94%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                      "
                    >
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l4 level1 lfo6;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          mso-bidi-font-weight: bold;
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span style="mso-bidi-font-weight: bold"
                          >Hizmet sa&#287;lay&#305;c&#305; ba&#351;vuru
                          s&#252;re&#231;lerinin
                          y&#252;r&#252;t&#252;lmesi</span
                        ><span style="mso-bidi-font-weight: bold"> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l4 level1 lfo6;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span
                          >S&#246;zle&#351;mesel ve yasal
                          y&#252;k&#252;ml&#252;l&#252;klerimizin tam ve
                          gere&#287;i gibi ifa edilebilmesi,</span
                        ><span> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l4 level1 lfo6;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span
                          >Ticari elektronik ileti&#351;ime ili&#351;kin
                          mevzuattan do&#287;an
                          y&#252;k&#252;ml&#252;l&#252;klerin yerine
                          getirilmesi,</span
                        ><span> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l4 level1 lfo6;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          color: rgb(0, 0, 0);
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span
                          >Hukuk i&#351;lerinin takibi ve
                          y&#252;r&#252;t&#252;lmesi</span
                        ><span> </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="32.7600%"
                      valign="top"
                      style="
                        width: 32.76%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                      "
                    >
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.8pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l14 level1 lfo2;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span>Kimlik Bilgileriniz </span><span> </span>
                      </p>
                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: -2.2pt;
                          text-align: left;
                          line-height: 114%;
                        "
                      >
                        <span>(</span
                        ><i
                          ><span style="font-style: italic"
                            >MERS&#304;S numaras&#305;, ticaret
                            unvan&#305;</span
                          ></i
                        ><span>)</span><span> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.8pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l14 level1 lfo2;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span>Di&#287;er Bilgileriniz </span><span> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 29.95pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l6 level1 lfo7;
                        "
                      >
                        <span
                          style="
                          font-family: 'Courier New';
                          mso-fareast-
                          font-style: italic;
                          
                        "
                          ><span style="mso-list: Ignore"
                            >o<span>&nbsp;</span></span
                          ></span
                        >
                        <i
                          ><span style="font-style: italic"
                            >(Ba&#351;vuru numaras&#305;,</span
                          ></i
                        ><i><span style="font-style: italic"> </span></i>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 29.95pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l6 level1 lfo7;
                        "
                      >
                        <span
                          style="
                          font-family: 'Courier New';
                          mso-fareast-
                          font-style: italic;
                          
                        "
                          ><span style="mso-list: Ignore"
                            >o<span>&nbsp;</span></span
                          ></span
                        >
                        <i
                          ><span style="font-style: italic"
                            >Kullan&#305;c&#305; ad&#305; ve &#351;ifresi,</span
                          ></i
                        ><i><span style="font-style: italic"> </span></i>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 29.95pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l6 level1 lfo7;
                        "
                      >
                        <span
                          style="
                          font-family: 'Courier New';
                          mso-fareast-
                          font-style: italic;
                          
                        "
                          ><span style="mso-list: Ignore"
                            >o<span>&nbsp;</span></span
                          ></span
                        >
                        <i
                          ><span style="font-style: italic"
                            >Varsa marka tescil belgesinde yer alan ismi ve
                            marka tescil belgesi,</span
                          ></i
                        ><i><span style="font-style: italic"> </span></i>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 29.95pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l6 level1 lfo7;
                        "
                      >
                        <span
                          style="
                          font-family: 'Courier New';
                          mso-fareast-
                          font-style: italic;
                          
                        "
                          ><span style="mso-list: Ignore"
                            >o<span>&nbsp;</span></span
                          ></span
                        >
                        <i
                          ><span style="font-style: italic"
                            >Yetkili ki&#351;i ad, soyad, e-posta,
                            telefon)</span
                          ></i
                        ><span> </span>
                      </p>
                    </td>
                    <td
                      width="31.2600%"
                      valign="top"
                      style="
                        width: 31.26%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                      "
                    >
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.6pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l8 level1 lfo5;
                        "
                      >
                        <span style="font-family: Symbol; font-size: 12pt"
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span class="16">KVKK madde 5/2-a:</span
                        ><span class="16"> </span>
                      </p>
                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: -2.4pt;
                          text-align: left;
                          line-height: 114%;
                        "
                      >
                        <span class="16"
                          >Kanunlarda a&#231;&#305;k&#231;a
                          &#246;ng&#246;r&#252;lmesi (</span
                        ><i
                          ><span class="16" style="font-style: italic"
                            >6563 say&#305;l&#305; Elektronik Ticaretin
                            D&#252;zenlenmesi Hakk&#305;nda Kanun ve Ticari
                            &#304;leti&#351;im ve Ticari Elektronik
                            &#304;letiler Hakk&#305;nda Y&#246;netmelik</span
                          ></i
                        ><span class="16">),</span><span class="16"> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.6pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l8 level1 lfo5;
                        "
                      >
                        <span style="font-family: Symbol; font-size: 12pt"
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span class="16">KVKK madde 5/2-&#231;:</span
                        ><span class="16"> </span>
                      </p>
                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: -2.4pt;
                          text-align: left;
                          line-height: 114%;
                        "
                      >
                        <span class="16"
                          >Veri sorumlusunun hukuki
                          y&#252;k&#252;ml&#252;l&#252;&#287;&#252;n&#252;
                          yerine getirebilmesi i&#231;in zorunlu
                          olmas&#305;</span
                        ><span class="16"> </span>
                      </p>
                    </td>
                    <td
                      width="35.9400%"
                      valign="top"
                      style="
                        width: 35.94%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                      "
                    >
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l4 level1 lfo6;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          mso-bidi-font-weight: bold;
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span style="mso-bidi-font-weight: bold"
                          >&#304;YS ba&#351;vuru do&#287;rulama ve kay&#305;t
                          s&#252;recinin y&#252;r&#252;t&#252;lmesi</span
                        ><span style="mso-bidi-font-weight: bold"> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l4 level1 lfo6;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span
                          >S&#246;zle&#351;mesel ve yasal
                          y&#252;k&#252;ml&#252;l&#252;klerimizin tam ve
                          gere&#287;i gibi ifa edilebilmesi,</span
                        ><span> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l4 level1 lfo6;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span
                          >Ticari elektronik ileti&#351;ime ili&#351;kin
                          mevzuattan do&#287;an
                          y&#252;k&#252;ml&#252;l&#252;klerin yerine
                          getirilmesi,</span
                        ><span> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l4 level1 lfo6;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          color: rgb(0, 0, 0);
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span
                          >Hukuk i&#351;lerinin takibi ve
                          y&#252;r&#252;t&#252;lmesi</span
                        ><span> </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="32.7600%"
                      valign="top"
                      style="
                        width: 32.76%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                      "
                    >
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.8pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l14 level1 lfo2;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span>Di&#287;er Bilgileriniz</span><span> </span>
                      </p>
                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          text-align: left;
                          line-height: 114%;
                        "
                      >
                        <span>(</span
                        ><i
                          ><span style="font-style: italic"
                            >Yetkili ki&#351;i ad, soyad, TCKN</span
                          ></i
                        ><span>)</span><span> </span>
                      </p>
                    </td>
                    <td
                      width="31.2600%"
                      valign="top"
                      style="
                        width: 31.26%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                      "
                    >
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.6pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l8 level1 lfo5;
                        "
                      >
                        <span style="font-family: Symbol; font-size: 12pt"
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span class="16">KVKK madde 5/2-a:</span
                        ><span class="16"> </span>
                      </p>
                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: -2.4pt;
                          text-align: left;
                          line-height: 114%;
                        "
                      >
                        <span class="16"
                          >Kanunlarda a&#231;&#305;k&#231;a
                          &#246;ng&#246;r&#252;lmesi (</span
                        ><i
                          ><span class="16" style="font-style: italic"
                            >6563 say&#305;l&#305; Elektronik Ticaretin
                            D&#252;zenlenmesi Hakk&#305;nda Kanun ve Ticari
                            &#304;leti&#351;im ve Ticari Elektronik
                            &#304;letiler Hakk&#305;nda Y&#246;netmelik</span
                          ></i
                        ><span class="16">),</span><span class="16"> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.6pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l8 level1 lfo5;
                        "
                      >
                        <span style="font-family: Symbol; font-size: 12pt"
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span class="16">KVKK madde 5/2-&#231;:</span
                        ><span class="16"> </span>
                      </p>
                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: -2.4pt;
                          text-align: left;
                          line-height: 114%;
                        "
                      >
                        <span class="16"
                          >Veri sorumlusunun hukuki
                          y&#252;k&#252;ml&#252;l&#252;&#287;&#252;n&#252;
                          yerine getirebilmesi i&#231;in zorunlu
                          olmas&#305;</span
                        ><span class="16"> </span>
                      </p>
                    </td>
                    <td
                      width="35.9400%"
                      valign="top"
                      style="
                        width: 35.94%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                      "
                    >
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l4 level1 lfo6;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          mso-bidi-font-weight: bold;
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span style="mso-bidi-font-weight: bold"
                          >&#304;YS giri&#351;i yap&#305;lmas&#305;
                          s&#252;recinin y&#252;r&#252;t&#252;lmesi</span
                        ><span style="mso-bidi-font-weight: bold"> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l4 level1 lfo6;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span
                          >S&#246;zle&#351;mesel ve yasal
                          y&#252;k&#252;ml&#252;l&#252;klerimizin tam ve
                          gere&#287;i gibi ifa edilebilmesi,</span
                        ><span style="mso-bidi-font-weight: bold"> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l4 level1 lfo6;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span
                          >Ticari elektronik ileti&#351;ime ili&#351;kin
                          mevzuattan do&#287;an
                          y&#252;k&#252;ml&#252;l&#252;klerin yerine
                          getirilmesi,</span
                        ><span> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l4 level1 lfo6;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          color: rgb(0, 0, 0);
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span
                          >Hukuk i&#351;lerinin takibi ve
                          y&#252;r&#252;t&#252;lmesi</span
                        ><span> </span>
                      </p>
                    </td>
                  </tr>
                  <tr style="height: 76.35pt">
                    <td
                      width="32.7600%"
                      valign="top"
                      style="
                        width: 32.76%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                      "
                    >
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.8pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l14 level1 lfo2;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span>Di&#287;er Bilgileriniz</span><span> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 29.95pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l1 level1 lfo8;
                        "
                      >
                        <span
                          style="
                          font-family: 'Courier New';
                          mso-fareast-
                          font-size: 10pt;
                        "
                          ><span style="mso-list: Ignore"
                            >o<span>&nbsp;</span></span
                          ></span
                        >
                        <span style="font-size: 10pt">(</span
                        ><i
                          ><span style="font-style: italic"
                            >Yetkili ki&#351;i ad, soyad, TCKN,</span
                          ></i
                        ><i><span style="font-style: italic"> </span></i>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 29.95pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l1 level1 lfo8;
                        "
                      >
                        <span
                          style="
                          font-family: 'Courier New';
                          mso-fareast-
                          font-style: italic;
                          
                        "
                          ><span style="mso-list: Ignore"
                            >o<span>&nbsp;</span></span
                          ></span
                        >
                        <i
                          ><span style="font-style: italic"
                            >Vatanda&#351;
                          </span></i
                        ><i
                          ><span
                            style="
                              letter-spacing: -0.05pt;
                              mso-bidi-font-weight: bold;
                              font-style: italic;
                            "
                            >ticari elektronik ileti tercihleri
                          </span></i
                        ><i
                          ><span
                            style="letter-spacing: -0.05pt; font-style: italic"
                            >(izin tarihi, izin kayna&#287;&#305;, izin
                            tipi))</span
                          ></i
                        ><span> </span>
                      </p>
                    </td>
                    <td
                      width="31.2600%"
                      valign="top"
                      style="
                        width: 31.26%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                      "
                    >
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.6pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l8 level1 lfo5;
                        "
                      >
                        <span style="font-family: Symbol; font-size: 12pt"
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span class="16">KVKK madde 5/2-a:</span
                        ><span class="16"> </span>
                      </p>
                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: -2.4pt;
                          text-align: left;
                          line-height: 114%;
                        "
                      >
                        <span class="16"
                          >Kanunlarda a&#231;&#305;k&#231;a
                          &#246;ng&#246;r&#252;lmesi (</span
                        ><i
                          ><span class="16" style="font-style: italic"
                            >6563 say&#305;l&#305; Elektronik Ticaretin
                            D&#252;zenlenmesi Hakk&#305;nda Kanun ve Ticari
                            &#304;leti&#351;im ve Ticari Elektronik
                            &#304;letiler Hakk&#305;nda Y&#246;netmelik</span
                          ></i
                        ><span class="16">),</span><span class="16"> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.6pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l8 level1 lfo5;
                        "
                      >
                        <span style="font-family: Symbol; font-size: 12pt"
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span class="16">KVKK madde 5/2-&#231;:</span
                        ><span class="16"> </span>
                      </p>
                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          text-align: left;
                          line-height: 114%;
                        "
                      >
                        <span class="16"
                          >Veri sorumlusunun hukuki
                          y&#252;k&#252;ml&#252;l&#252;&#287;&#252;n&#252;
                          yerine getirebilmesi i&#231;in zorunlu
                          olmas&#305;</span
                        ><u
                          ><span
                            class="16"
                            style="
                              text-decoration: underline;
                              text-underline: single;

                              background: rgb(255, 255, 0);
                              mso-highlight: rgb(255, 255, 0);
                            "
                          >
                          </span
                        ></u>
                      </p>
                    </td>
                    <td
                      width="35.9400%"
                      valign="top"
                      style="
                        width: 35.94%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                      "
                    >
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l9 level1 lfo9;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span
                          >&#304;YS ekranlar&#305; &#252;zerinden onay-red
                          i&#351;lemlerinin y&#252;r&#252;t&#252;lmesi</span
                        ><span> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l9 level1 lfo9;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span
                          >S&#246;zle&#351;mesel ve yasal
                          y&#252;k&#252;ml&#252;l&#252;klerimizin tam ve
                          gere&#287;i gibi ifa edilebilmesi,</span
                        ><span> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l9 level1 lfo9;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span
                          >Ticari elektronik ileti&#351;ime ili&#351;kin
                          mevzuattan do&#287;an
                          y&#252;k&#252;ml&#252;l&#252;klerin yerine
                          getirilmesi,</span
                        ><span> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l9 level1 lfo9;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          color: rgb(0, 0, 0);
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span
                          >Hukuk i&#351;lerinin takibi ve
                          y&#252;r&#252;t&#252;lmesi,</span
                        ><span> </span>
                      </p>
                    </td>
                  </tr>
                  <tr style="height: 76.35pt">
                    <td
                      width="32.7600%"
                      valign="top"
                      style="
                        width: 32.76%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                      "
                    >
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.6pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l8 level1 lfo5;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          letter-spacing: -0.05pt;
                          mso-bidi-font-weight: bold;
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span
                          style="
                            letter-spacing: -0.05pt;
                            mso-bidi-font-weight: bold;
                          "
                          >&#304;&#351;lem G&#252;venli&#287;i Verileriniz</span
                        ><span style="letter-spacing: -0.05pt"
                          ><span style="mso-spacerun: 'yes'">&nbsp;</span
                          >(</span
                        ><i
                          ><span style="font-style: italic"
                            >IP Adresleri ve &#199;evrimi&#231;i
                            Belirleyiciler</span
                          ></i
                        ><span style="letter-spacing: -0.05pt">)</span
                        ><span> </span>
                      </p>
                    </td>
                    <td
                      width="31.2600%"
                      valign="top"
                      style="
                        width: 31.26%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                      "
                    >
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.6pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l8 level1 lfo5;
                        "
                      >
                        <span style="font-family: Symbol; font-size: 12pt"
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span class="16">KVKK madde 5/2-a:</span
                        ><span class="16"> </span>
                      </p>
                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: -2.4pt;
                          text-align: left;
                          line-height: 114%;
                        "
                      >
                        <span class="16"
                          >Kanunlarda a&#231;&#305;k&#231;a
                          &#246;ng&#246;r&#252;lmesi (</span
                        ><i
                          ><span style="font-style: italic"
                            >5651 say&#305;l&#305; &#304;nternet Ortam&#305;nda
                            Yap&#305;lan Yay&#305;nlar&#305;n D&#252;zenlenmesi
                            ve Bu Yay&#305;nlar Yoluyla &#304;&#351;lenen
                            Su&#231;larla M&#252;cadele Edilmesi Hakk&#305;nda
                            Kanun</span
                          ></i
                        ><span class="16">),</span><span class="16"> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.6pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l8 level1 lfo5;
                        "
                      >
                        <span style="font-family: Symbol; font-size: 12pt"
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span class="16">KVKK madde 5/2-&#231;:</span
                        ><span class="16"> </span>
                      </p>
                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          text-align: left;
                          line-height: 114%;
                        "
                      >
                        <span class="16"
                          >Veri sorumlusunun hukuki
                          y&#252;k&#252;ml&#252;l&#252;&#287;&#252;n&#252;
                          yerine getirebilmesi i&#231;in zorunlu
                          olmas&#305;</span
                        ><span class="16"> </span>
                      </p>
                    </td>
                    <td
                      width="35.9400%"
                      valign="top"
                      style="
                        width: 35.94%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                      "
                    >
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l11 level1 lfo10;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          color: rgb(0, 0, 0);
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span
                          >Bilgi g&#252;venli&#287;i s&#252;re&#231;lerinin
                          y&#252;r&#252;t&#252;lmesi amac&#305;yla log
                          kay&#305;tlar&#305;n&#305;n tutulmas&#305;,</span
                        ><span> </span>
                      </p>
                      <p
                        class="46"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          line-height: 114%;
                          mso-list: l11 level1 lfo10;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          color: rgb(0, 0, 0);
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span
                          >Faaliyetlerin mevzuata uygun
                          y&#252;r&#252;t&#252;lmesi</span
                        ><span> </span>
                      </p>
                    </td>
                  </tr>
                </table>
              </div>

              <p
                class="46"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  margin-left: 18pt;
                  mso-para-margin-left: 0gd;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                "
              >
                <b style="mso-bidi-font-weight: normal"
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                  >
                  </span
                ></b>
              </p>
              <p
                class="46"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  margin-left: 36pt;
                  text-indent: -18pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  mso-list: l13 level1 lfo1;
                "
              >
                <span style="font-weight: bold"
                  ><span style="mso-list: Ignore"
                    >2.<span>&nbsp;</span></span
                  ></span
                >
                <b style="mso-bidi-font-weight: normal"
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                    >Ki&#351;isel Verilerinizin &#220;&#231;&#252;nc&#252;
                    Ki&#351;ilerle Payla&#351;&#305;lmas&#305;</span
                  ></b
                ><b style="mso-bidi-font-weight: normal"
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                  >
                  </span
                ></b>
              </p>
              <p
                class="46"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  margin-left: 36pt;
                  text-indent: -18pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  mso-list: l0 level1 lfo11;
                "
              >
                <span style="letter-spacing: 0pt; font-weight: bold"
                  ><span style="mso-list: Ignore"
                    >2.1.<span>&nbsp;</span></span
                  ></span
                >
                <b style="mso-bidi-font-weight: normal"
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                    >Yurt &#304;&#231;i Aktar&#305;m</span
                  ></b
                ><b style="mso-bidi-font-weight: normal"
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                  >
                  </span
                ></b>
              </p>
              <p
                class="MsoNormal"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                "
              >
                <span
                  style="
                    mso-spacerun: 'yes';

                    mso-bidi-font-weight: bold;
                  "
                  >Yukar&#305;da an&#305;lan ki&#351;isel verilerinizin
                  a&#351;a&#287;&#305;da an&#305;lan ama&#231;lar ve hukuka
                  uygunluk sebepleri uyar&#305;nca
                  aktar&#305;lmaktad&#305;r.</span
                ><span
                  style="
                    mso-spacerun: 'yes';

                    mso-bidi-font-weight: bold;
                  "
                >
                </span>
              </p>
              <p
                class="46"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                "
              >
                <b style="mso-bidi-font-weight: normal"
                  ><i style="mso-bidi-font-style: normal"
                    ><span
                      style="
                        mso-spacerun: 'yes';

                        font-weight: bold;
                        mso-ansi-font-style: italic;
                      "
                      >Tablo-2</span
                    ></i
                  ></b
                ><b style="mso-bidi-font-weight: normal"
                  ><i style="mso-bidi-font-style: normal"
                    ><span
                      style="
                        mso-spacerun: 'yes';

                        font-weight: bold;
                        mso-ansi-font-style: italic;
                      "
                    >
                    </span></i
                ></b>
              </p>
              <div style="overflow-x: auto">
                <table
                  class="MsoTableGrid"
                  border="1"
                  cellspacing="0"
                  style="
                    border-collapse: collapse;
                    border: none;
                    mso-border-left-alt: 0.5pt solid windowtext;
                    mso-border-top-alt: 0.5pt solid windowtext;
                    mso-border-right-alt: 0.5pt solid windowtext;
                    mso-border-bottom-alt: 0.5pt solid windowtext;
                    mso-border-insideh: 0.5pt solid windowtext;
                    mso-border-insidev: 0.5pt solid windowtext;
                    mso-padding-alt: 0pt 5.4pt 0pt 5.4pt;
                  "
                >
                  <tr>
                    <td
                      width="30.5600%"
                      valign="top"
                      style="
                        width: 30.56%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: 1pt solid windowtext;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                        background: rgb(208, 206, 206);
                      "
                    >
                      <p
                        class="MsoNormal"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                        "
                      >
                        <b style="mso-bidi-font-weight: normal"
                          ><span style="font-weight: bold"
                            >Ki&#351;isel Veriniz</span
                          ></b
                        ><b><span style="font-weight: bold"> </span></b>
                      </p>
                    </td>
                    <td
                      width="25.6600%"
                      valign="top"
                      style="
                        width: 25.66%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: 1pt solid windowtext;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                        background: rgb(208, 206, 206);
                      "
                    >
                      <p
                        class="MsoNormal"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                        "
                      >
                        <b
                          ><span style="font-weight: bold"
                            >Aktar&#305;m Amac&#305;</span
                          ></b
                        ><b><span style="font-weight: bold"> </span></b>
                      </p>
                    </td>
                    <td
                      width="23.4400%"
                      valign="top"
                      style="
                        width: 23.44%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: 1pt solid windowtext;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                        background: rgb(208, 206, 206);
                      "
                    >
                      <p
                        class="MsoNormal"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                        "
                      >
                        <b
                          ><span style="font-weight: bold"
                            >Hukuka Uygunluk Sebebi</span
                          ></b
                        ><b><span style="font-weight: bold"> </span></b>
                      </p>
                    </td>
                    <td
                      width="20.3000%"
                      valign="top"
                      style="
                        width: 20.3%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: 1pt solid windowtext;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                        background: rgb(208, 206, 206);
                      "
                    >
                      <p
                        class="MsoNormal"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                        "
                      >
                        <b
                          ><span style="font-weight: bold"
                            >Al&#305;c&#305; Grubu</span
                          ></b
                        ><b><span style="font-weight: bold"> </span></b>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="30.5600%"
                      valign="top"
                      style="
                        width: 30.56%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                      "
                    >
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.8pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l14 level1 lfo2;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span>Kimlik Bilgileriniz</span><span> </span>
                      </p>
                      <p
                        class="MsoNormal"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: -2.2pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                        "
                      >
                        <span>(</span
                        ><i
                          ><span style="font-style: italic"
                            >MERS&#304;S numaras&#305;, ticaret
                            unvan&#305;</span
                          ></i
                        ><span>)</span><span> </span>
                      </p>
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.8pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l14 level1 lfo2;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span>Di&#287;er Bilgileriniz </span><span> </span>
                      </p>
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 29.95pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l10 level1 lfo12;
                        "
                      >
                        <span
                          style="
                          font-family: 'Courier New';
                          mso-fareast-
                          font-style: italic;
                          
                        "
                          ><span style="mso-list: Ignore"
                            >o<span>&nbsp;</span></span
                          ></span
                        >
                        <i
                          ><span style="font-style: italic"
                            >(Ba&#351;vuru numaras&#305;,</span
                          ></i
                        ><i><span style="font-style: italic"> </span></i>
                      </p>
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 29.95pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l10 level1 lfo12;
                        "
                      >
                        <span
                          style="
                          font-family: 'Courier New';
                          mso-fareast-
                          font-style: italic;
                          
                        "
                          ><span style="mso-list: Ignore"
                            >o<span>&nbsp;</span></span
                          ></span
                        >
                        <i
                          ><span style="font-style: italic"
                            >Kullan&#305;c&#305; Ad&#305; ve &#350;ifresi,</span
                          ></i
                        ><i><span style="font-style: italic"> </span></i>
                      </p>
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 29.95pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l10 level1 lfo12;
                        "
                      >
                        <span
                          style="
                          font-family: 'Courier New';
                          mso-fareast-
                          font-style: italic;
                          
                        "
                          ><span style="mso-list: Ignore"
                            >o<span>&nbsp;</span></span
                          ></span
                        >
                        <i
                          ><span style="font-style: italic"
                            >Varsa marka tescil belgesinde yer alan ismi ve
                            marka tescil belgesi,</span
                          ></i
                        ><i><span style="font-style: italic"> </span></i>
                      </p>
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 29.95pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l10 level1 lfo12;
                        "
                      >
                        <span
                          style="
                          font-family: 'Courier New';
                          mso-fareast-
                          font-style: italic;
                          
                        "
                          ><span style="mso-list: Ignore"
                            >o<span>&nbsp;</span></span
                          ></span
                        >
                        <i
                          ><span style="font-style: italic"
                            >Yetkili ki&#351;inin ad,soy ad, e-posta, telefon
                            bilgileri</span
                          ></i
                        ><i><span style="font-style: italic"> </span></i>
                      </p>
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 29.95pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l10 level1 lfo12;
                        "
                      >
                        <span
                          style="
                          font-family: 'Courier New';
                          mso-fareast-
                          font-style: italic;
                          
                        "
                          ><span style="mso-list: Ignore"
                            >o<span>&nbsp;</span></span
                          ></span
                        >
                        <i
                          ><span style="font-style: italic"
                            >Vatanda&#351;
                          </span></i
                        ><i
                          ><span
                            style="
                              letter-spacing: -0.05pt;
                              mso-bidi-font-weight: bold;
                              font-style: italic;
                            "
                            >ticari elektronik ileti tercihleri</span
                          ></i
                        ><i
                          ><span style="font-style: italic"
                            ><span style="mso-spacerun: 'yes'"
                              >&nbsp;</span
                            ></span
                          ></i
                        ><i
                          ><span
                            style="letter-spacing: -0.05pt; font-style: italic"
                            >(&#304;zin tarihi, izin kayna&#287;&#305;, izin
                            tipi)</span
                          ></i
                        ><span> </span>
                      </p>
                    </td>
                    <td
                      width="25.6600%"
                      valign="top"
                      style="
                        width: 25.66%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                      "
                    >
                      <p
                        class="MsoNormal"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                        "
                      >
                        <span
                          >&#304;leti Y&#246;netim Sisteminin
                          &#304;&#351;letilmesi</span
                        ><span> </span>
                      </p>
                    </td>
                    <td
                      width="23.4400%"
                      valign="top"
                      style="
                        width: 23.44%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                      "
                    >
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.8pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l14 level1 lfo2;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span class="16">KVKK madde 5/2-&#231;:</span
                        ><span class="16"> </span>
                      </p>
                      <p
                        class="MsoNormal"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                        "
                      >
                        <span class="16"
                          >Veri sorumlusunun hukuki
                          y&#252;k&#252;ml&#252;l&#252;&#287;&#252;n&#252;
                          yerine getirebilmesi i&#231;in zorunlu
                          olmas&#305;</span
                        ><b><span style="font-weight: bold"> </span></b>
                      </p>
                    </td>
                    <td
                      width="20.3000%"
                      valign="top"
                      style="
                        width: 20.3%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                      "
                    >
                      <p
                        class="MsoNormal"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                        "
                      >
                        <span>&#304;&#351; Orta&#287;&#305;</span><span> </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="30.5600%"
                      valign="top"
                      style="
                        width: 30.56%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                      "
                    >
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.8pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l14 level1 lfo2;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span>Kimlik Bilgileriniz </span><span> </span>
                      </p>
                      <p
                        class="MsoNormal"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: -2.2pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                        "
                      >
                        <span>(</span
                        ><i
                          ><span style="font-style: italic"
                            >MERS&#304;S numaras&#305; ve ticari unvan,</span
                          ></i
                        ><span
                          ><span style="mso-spacerun: 'yes'">&nbsp;</span></span
                        ><i
                          ><span style="font-style: italic"
                            >VKN/TCKN vergi dairesi, e-imza)</span
                          ></i
                        ><span
                          ><span style="mso-spacerun: 'yes'">&nbsp;</span></span
                        ><span> </span>
                      </p>
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.8pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l14 level1 lfo2;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span>&#304;leti&#351;im Bilgileriniz </span
                        ><span> </span>
                      </p>
                      <p
                        class="MsoNormal"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                        "
                      >
                        <span>(</span
                        ><i
                          ><span style="font-style: italic"
                            >Ba&#287;l&#305; oldu&#287;unuz il, il&#231;e ve
                            ileti&#351;im adresi, sabit ileti&#351;im
                            numaras&#305;, internet sitesi</span
                          ></i
                        ><span>)</span><span> </span>
                      </p>
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.8pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l12 level1 lfo3;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span>Di&#287;er Bilgileriniz </span><span> </span>
                      </p>
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 22.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l2 level1 lfo4;
                        "
                      >
                        <span
                          style="
                          font-family: 'Courier New';
                          mso-fareast-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >o<span>&nbsp;</span></span
                          ></span
                        >
                        <span>(</span
                        ><i
                          ><span style="font-style: italic"
                            >Yetkili ki&#351;i ad, soy ad, TCKN,
                          </span></i
                        ><i><span style="font-style: italic"> </span></i>
                      </p>
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 22.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l2 level1 lfo4;
                        "
                      >
                        <span
                          style="
                          font-family: 'Courier New';
                          mso-fareast-
                          font-style: italic;
                          
                        "
                          ><span style="mso-list: Ignore"
                            >o<span>&nbsp;</span></span
                          ></span
                        >
                        <i
                          ><span style="font-style: italic"
                            >Yetkili ki&#351;i e-posta adresi, telefon,</span
                          ></i
                        ><i><span style="font-style: italic"> </span></i>
                      </p>
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 22.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l2 level1 lfo4;
                        "
                      >
                        <span
                          style="
                          font-family: 'Courier New';
                          mso-fareast-
                          font-style: italic;
                          
                        "
                          ><span style="mso-list: Ignore"
                            >o<span>&nbsp;</span></span
                          ></span
                        >
                        <i
                          ><span style="font-style: italic"
                            >Marka tescil belgesinde yer alan ismi ve marka
                            tescil belgesi,</span
                          ></i
                        ><i><span style="font-style: italic"> </span></i>
                      </p>
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 22.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l2 level1 lfo4;
                        "
                      >
                        <span
                          style="
                          font-family: 'Courier New';
                          mso-fareast-
                          font-style: italic;
                          
                        "
                          ><span style="mso-list: Ignore"
                            >o<span>&nbsp;</span></span
                          ></span
                        >
                        <i
                          ><span style="font-style: italic"
                            >Ba&#351;vuru numaras&#305;,</span
                          ></i
                        ><i><span style="font-style: italic"> </span></i>
                      </p>
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 22.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l2 level1 lfo4;
                        "
                      >
                        <span
                          style="
                          font-family: 'Courier New';
                          mso-fareast-
                          font-style: italic;
                          
                        "
                          ><span style="mso-list: Ignore"
                            >o<span>&nbsp;</span></span
                          ></span
                        >
                        <i
                          ><span style="font-style: italic"
                            >Kullan&#305;c&#305; Ad&#305; ve &#350;ifresi</span
                          ></i
                        ><i><span style="font-style: italic"> </span></i>
                      </p>
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 22.85pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l2 level1 lfo4;
                        "
                      >
                        <span
                          style="
                          font-family: 'Courier New';
                          mso-fareast-
                          font-style: italic;
                          
                        "
                          ><span style="mso-list: Ignore"
                            >o<span>&nbsp;</span></span
                          ></span
                        >
                        <i
                          ><span style="font-style: italic"
                            >Vatanda&#351; t</span
                          ></i
                        ><i
                          ><span
                            style="
                              letter-spacing: -0.05pt;
                              mso-bidi-font-weight: bold;
                              font-style: italic;
                            "
                            >icari elektronik ileti tercihleri</span
                          ></i
                        ><i
                          ><span style="font-style: italic"
                            ><span style="mso-spacerun: 'yes'"
                              >&nbsp;</span
                            ></span
                          ></i
                        ><i
                          ><span
                            style="letter-spacing: -0.05pt; font-style: italic"
                            >(&#304;zin tarihi, izin kayna&#287;&#305;, icing
                            tipi</span
                          ></i
                        ><i><span style="font-style: italic">))</span></i
                        ><span> </span>
                      </p>
                    </td>
                    <td
                      width="25.6600%"
                      valign="top"
                      style="
                        width: 25.66%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                      "
                    >
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 13.45pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l14 level1 lfo2;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span
                          >Yetkili Ki&#351;i, Kurum ve Kurulu&#351;lara Bilgi
                          Verilmesi, </span
                        ><span> </span>
                      </p>
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 13.45pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l14 level1 lfo2;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span
                          >Hukuk &#304;&#351;lerinin Takibi ve
                          Y&#252;r&#252;t&#252;lmesi</span
                        ><span> </span>
                      </p>
                    </td>
                    <td
                      width="23.4400%"
                      valign="top"
                      style="
                        width: 23.44%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                      "
                    >
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 13.45pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l14 level1 lfo2;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span class="16">KVKK madde 5/2-&#231;:</span
                        ><span class="16"> </span>
                      </p>
                      <p
                        class="MsoNormal"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                        "
                      >
                        <span class="16"
                          >Veri sorumlusunun hukuki
                          y&#252;k&#252;ml&#252;l&#252;&#287;&#252;n&#252;
                          yerine getirebilmesi i&#231;in zorunlu
                          olmas&#305;</span
                        ><span> </span>
                      </p>
                    </td>
                    <td
                      width="20.3000%"
                      valign="top"
                      style="
                        width: 20.3%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                      "
                    >
                      <p
                        class="MsoNormal"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                        "
                      >
                        <span>Yetkili kamu kurum ve kurulu&#351;lar&#305;</span
                        ><span> </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="30.5600%"
                      valign="top"
                      style="
                        width: 30.56%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                        background: rgb(255, 255, 255);
                      "
                    >
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.6pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l7 level1 lfo13;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          letter-spacing: -0.05pt;
                          mso-bidi-font-weight: bold;
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span
                          style="
                            letter-spacing: -0.05pt;
                            mso-bidi-font-weight: bold;
                          "
                          >&#304;&#351;lem G&#252;venli&#287;i
                          Kay&#305;tlar&#305;n&#305;z</span
                        ><span style="letter-spacing: -0.05pt"
                          ><span style="mso-spacerun: 'yes'">&nbsp;</span></span
                        ><span style="letter-spacing: -0.05pt"> </span>
                      </p>
                      <p
                        class="MsoNormal"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                        "
                      >
                        <span style="letter-spacing: -0.05pt">(</span
                        ><i
                          ><span style="font-style: italic"
                            >IP Adresleri ve &#199;evrimi&#231;i
                            Belirleyiciler</span
                          ></i
                        ><span style="letter-spacing: -0.05pt">)</span
                        ><span
                          style="
                            letter-spacing: -0.05pt;
                            mso-bidi-font-weight: bold;
                          "
                        >
                        </span>
                      </p>
                    </td>
                    <td
                      width="25.6600%"
                      valign="top"
                      style="
                        width: 25.66%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                        background: rgb(255, 255, 255);
                      "
                    >
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.6pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l7 level1 lfo13;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span
                          >Yetkili Ki&#351;i, Kurum ve Kurulu&#351;lara Bilgi
                          Verilmesi, </span
                        ><span> </span>
                      </p>
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.6pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l7 level1 lfo13;
                        "
                      >
                        <span
                          style="
                          font-family: Symbol;
                          mso-fareast-
                          mso-bidi-
                          
                        "
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span
                          >Hukuk &#304;&#351;lerinin Takibi ve
                          Y&#252;r&#252;t&#252;lmesi</span
                        ><span> </span>
                      </p>
                    </td>
                    <td
                      width="23.4400%"
                      valign="top"
                      style="
                        width: 23.44%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                        background: rgb(255, 255, 255);
                      "
                    >
                      <p
                        class="46"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          margin-left: 15.95pt;
                          text-indent: -18pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                          mso-list: l7 level1 lfo13;
                        "
                      >
                        <span style="font-family: Symbol; font-size: 12pt"
                          ><span style="mso-list: Ignore"
                            >&#183;<span>&nbsp;</span></span
                          ></span
                        >
                        <span class="16">KVKK madde 5/2-&#231;:</span
                        ><span class="16"> </span>
                      </p>
                      <p
                        class="MsoNormal"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                        "
                      >
                        <span class="16"
                          >Veri sorumlusunun hukuki
                          y&#252;k&#252;ml&#252;l&#252;&#287;&#252;n&#252;
                          yerine getirebilmesi i&#231;in zorunlu
                          olmas&#305;</span
                        ><span> </span>
                      </p>
                    </td>
                    <td
                      width="20.3000%"
                      valign="top"
                      style="
                        width: 20.3%;
                        padding: 0pt 5.4pt 0pt 5.4pt;
                        border-left: 1pt solid windowtext;
                        mso-border-left-alt: 0.5pt solid windowtext;
                        border-right: 1pt solid windowtext;
                        mso-border-right-alt: 0.5pt solid windowtext;
                        border-top: none;
                        mso-border-top-alt: 0.5pt solid windowtext;
                        border-bottom: 1pt solid windowtext;
                        mso-border-bottom-alt: 0.5pt solid windowtext;
                        background: rgb(255, 255, 255);
                      "
                    >
                      <p
                        class="MsoNormal"
                        align="justify"
                        style="
                          margin-top: 6pt;
                          margin-bottom: 6pt;
                          text-align: left;
                          text-justify: inter-ideograph;
                          line-height: 114%;
                        "
                      >
                        <span>Yetkili kamu kurum ve kurulu&#351;lar&#305;</span
                        ><span> </span>
                      </p>
                    </td>
                  </tr>
                </table>
              </div>

              <p
                class="46"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  margin-left: 18pt;
                  mso-para-margin-left: 0gd;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                "
              >
                <b style="mso-bidi-font-weight: normal"
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                  >
                  </span
                ></b>
              </p>
              <p
                class="46"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  margin-left: 36pt;
                  text-indent: -18pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  mso-list: l0 level1 lfo11;
                "
              >
                <span style="letter-spacing: 0pt; font-weight: bold"
                  ><span style="mso-list: Ignore"
                    >2.2.<span>&nbsp;</span></span
                  ></span
                >
                <b style="mso-bidi-font-weight: normal"
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                    >Yurt D&#305;&#351;&#305; Aktar&#305;m</span
                  ></b
                ><b style="mso-bidi-font-weight: normal"
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                  >
                  </span
                ></b>
              </p>
              <p
                class="MsoNormal"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                "
              >
                <span
                  style="
                    mso-spacerun: 'yes';

                    mso-bidi-font-weight: bold;
                  "
                  >Ticaret Bakanl&#305;&#287;&#305; yukar&#305;da say&#305;lan
                  ki&#351;isel verilerinizi yurt d&#305;&#351;&#305;na
                  aktarmamaktad&#305;r. </span
                ><span
                  style="
                    mso-spacerun: 'yes';

                    mso-bidi-font-weight: bold;
                  "
                >
                </span>
              </p>
              <p
                class="46"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  margin-left: 36pt;
                  text-indent: -18pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  mso-list: l13 level1 lfo1;
                "
              >
                <span style="font-weight: bold"
                  ><span style="mso-list: Ignore"
                    >3.<span>&nbsp;</span></span
                  ></span
                >
                <b style="mso-bidi-font-weight: normal"
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                    >Ki&#351;isel Verilerinizin Korunmas&#305;, Muhafazas&#305;
                    ve &#304;mhas&#305;</span
                  ></b
                ><b style="mso-bidi-font-weight: normal"
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                  >
                  </span
                ></b>
              </p>
              <p
                class="MsoNormal"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                "
              >
                <span style="mso-spacerun: 'yes'"
                  >Ki&#351;isel verileriniz, </span
                ><span
                  style="
                    mso-spacerun: 'yes';

                    mso-bidi-font-weight: bold;
                  "
                  >Ticaret Bakanl&#305;&#287;&#305;</span
                ><span style="mso-spacerun: 'yes'"
                  >&nbsp;taraf&#305;ndan ki&#351;isel verilerin i&#351;lenme
                  amac&#305;n&#305;n gerektirdi&#287;i s&#252;re boyunca
                  i&#351;lenir ve her h&#226;l&#252;k&#226;rda yasal
                  s&#252;renin sonuna kadar muhafaza edilir. Saklama
                  s&#252;resinin sona ermesinin ard&#305;ndan ki&#351;isel
                  verileriniz </span
                ><span
                  style="
                    mso-spacerun: 'yes';

                    mso-bidi-font-weight: bold;
                  "
                  >Ticaret Bakanl&#305;&#287;&#305;</span
                ><span style="mso-spacerun: 'yes'">&nbsp;</span
                ><span
                  style="
                    mso-spacerun: 'yes';

                    mso-bidi-font-weight: bold;
                    mso-bidi-font-style: italic;
                  "
                  >Ki&#351;isel Verilerin Korunmas&#305; ve Gizlili&#287;i
                  Politikas&#305;, &#214;zel Nitelikli Ki&#351;isel Verilerin
                  Korunmas&#305; ve Gizlili&#287;i Politikas&#305; ile
                  Ki&#351;isel Veri Saklama ve &#304;mha Politikas&#305; </span
                ><span style="mso-spacerun: 'yes'"
                  >do&#287;rultusunda KVK mevzuat&#305;na uygun olarak
                  elektronik ve fiziki ortamlardan silinir, yok edilir veya
                  anonim hale getirilir.</span
                ><span style="mso-spacerun: 'yes'"> </span>
              </p>
              <p
                class="MsoNormal"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                "
              >
                <span style="mso-spacerun: 'yes'"
                  >Ki&#351;isel verilerinize, yetkisiz ki&#351;iler
                  taraf&#305;ndan eri&#351;ilmesini, ki&#351;isel verilerinizin
                  hatal&#305; i&#351;lenmesini, if&#351;a edilmesini, hukuka
                  uygun olmayan sebeplerle
                  de&#287;i&#351;tirilmesini/silinmesini &#246;nlemek,
                  korunmas&#305;n&#305; ve g&#252;venli&#287;ini sa&#287;lamak
                  amac&#305;yla </span
                ><span
                  style="
                    mso-spacerun: 'yes';

                    mso-bidi-font-weight: bold;
                  "
                  >Ticaret Bakanl&#305;&#287;&#305;</span
                ><span style="mso-spacerun: 'yes'"
                  >&nbsp;KVK mevzuat&#305; uyar&#305;nca gerekli t&#252;m teknik
                  ve idari tedbirleri al&#305;r.</span
                ><span style="mso-spacerun: 'yes'"> </span>
              </p>
              <p
                class="MsoNormal"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                "
              >
                <span
                  style="
                    mso-spacerun: 'yes';

                    mso-bidi-font-weight: bold;
                  "
                  >Ticaret Bakanl&#305;&#287;&#305;</span
                ><span style="mso-spacerun: 'yes'"
                  >&nbsp;fiziki ar&#351;ivine ve/veya sunucular&#305;na ve/veya
                  di&#287;er sistemlerine yap&#305;lan sald&#305;r&#305;lar
                  sonucunda ki&#351;isel verilerinizin zarar g&#246;rmesi
                  ve/veya &#252;&#231;&#252;nc&#252; ki&#351;iler
                  taraf&#305;ndan ele ge&#231;irilmesi/if&#351;as&#305;
                  durumunda, Ticaret </span
                ><span
                  style="
                    mso-spacerun: 'yes';

                    mso-bidi-font-weight: bold;
                  "
                  >Bakanl&#305;&#287;&#305;</span
                ><span style="mso-spacerun: 'yes'"
                  >&nbsp;sizleri ve Ki&#351;isel Verileri Koruma Kurulunu derhal
                  bilgilendirir.</span
                ><span style="mso-spacerun: 'yes'"> </span>
              </p>
              <p
                class="46"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  margin-left: 36pt;
                  text-indent: -18pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  mso-list: l13 level1 lfo1;
                "
              >
                <span style="font-weight: bold"
                  ><span style="mso-list: Ignore"
                    >4.<span>&nbsp;</span></span
                  ></span
                >
                <b
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                    >Ki&#351;isel Verisi &#304;&#351;lenen &#304;lgili
                    Ki&#351;inin Haklar&#305;
                  </span></b
                ><b
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                  >
                  </span
                ></b>
              </p>
              <p
                class="MsoNormal"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                "
              >
                <span style="mso-spacerun: 'yes'"
                  >Kanun’un 11. maddesi h&#252;k&#252;mleri uyar&#305;nca
                  ki&#351;isel verilerinize ili&#351;ki olarak
                  a&#351;a&#287;&#305;daki haklar&#305;n&#305;z
                  bulunmaktad&#305;r.</span
                ><span style="mso-spacerun: 'yes'"> </span>
              </p>
              <p
                class="46"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  margin-left: 36pt;
                  text-indent: -18pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                  mso-list: l3 level1 lfo14;
                "
              >
                <span
                  style="
                    font-family: Symbol;
                    mso-fareast-
                    mso-bidi-
                    
                  "
                  ><span style="mso-list: Ignore"
                    >&#183;<span>&nbsp;</span></span
                  ></span
                >
                <span style="mso-spacerun: 'yes'"
                  >Ki&#351;isel verinin i&#351;lenip i&#351;lenmedi&#287;ini
                  &#246;&#287;renme,</span
                ><span style="mso-spacerun: 'yes'"> </span>
              </p>
              <p
                class="46"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  margin-left: 36pt;
                  text-indent: -18pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                  mso-list: l3 level1 lfo14;
                "
              >
                <span
                  style="
                    font-family: Symbol;
                    mso-fareast-
                    mso-bidi-
                    
                  "
                  ><span style="mso-list: Ignore"
                    >&#183;<span>&nbsp;</span></span
                  ></span
                >
                <span style="mso-spacerun: 'yes'"
                  >Ki&#351;isel verileri i&#351;lenmi&#351;se; buna ili&#351;kin
                  bilgi talep etme,</span
                ><span style="mso-spacerun: 'yes'"> </span>
              </p>
              <p
                class="46"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  margin-left: 36pt;
                  text-indent: -18pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                  mso-list: l3 level1 lfo14;
                "
              >
                <span
                  style="
                    font-family: Symbol;
                    mso-fareast-
                    mso-bidi-
                    
                  "
                  ><span style="mso-list: Ignore"
                    >&#183;<span>&nbsp;</span></span
                  ></span
                >
                <span style="mso-spacerun: 'yes'"
                  >Ki&#351;isel verilerin i&#351;lenme amac&#305;n&#305; ve
                  bunlar&#305;n amac&#305;na uygun kullan&#305;l&#305;p
                  kullan&#305;lmad&#305;&#287;&#305;n&#305;
                  &#246;&#287;renme,</span
                ><span style="mso-spacerun: 'yes'"> </span>
              </p>
              <p
                class="46"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  margin-left: 36pt;
                  text-indent: -18pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                  mso-list: l3 level1 lfo14;
                "
              >
                <span
                  style="
                    font-family: Symbol;
                    mso-fareast-
                    mso-bidi-
                    
                  "
                  ><span style="mso-list: Ignore"
                    >&#183;<span>&nbsp;</span></span
                  ></span
                >
                <span style="mso-spacerun: 'yes'"
                  >Yurt i&#231;inde veya yurt d&#305;&#351;&#305;nda
                  ki&#351;isel verilerin aktar&#305;ld&#305;&#287;&#305;
                  &#252;&#231;&#252;nc&#252; ki&#351;ileri bilme,</span
                ><span style="mso-spacerun: 'yes'"> </span>
              </p>
              <p
                class="46"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  margin-left: 36pt;
                  text-indent: -18pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                  mso-list: l3 level1 lfo14;
                "
              >
                <span
                  style="
                    font-family: Symbol;
                    mso-fareast-
                    mso-bidi-
                    
                  "
                  ><span style="mso-list: Ignore"
                    >&#183;<span>&nbsp;</span></span
                  ></span
                >
                <span style="mso-spacerun: 'yes'"
                  >Ki&#351;isel verilerin eksik veya yanl&#305;&#351;
                  i&#351;lenmi&#351; olmas&#305; h&#226;linde bunlar&#305;n
                  d&#252;zeltilmesini isteme</span
                ><span style="mso-spacerun: 'yes'"> </span>
              </p>
              <p
                class="46"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  margin-left: 36pt;
                  text-indent: -18pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                  mso-list: l3 level1 lfo14;
                "
              >
                <span
                  style="
                    font-family: Symbol;
                    mso-fareast-
                    mso-bidi-
                    
                  "
                  ><span style="mso-list: Ignore"
                    >&#183;<span>&nbsp;</span></span
                  ></span
                >
                <span style="mso-spacerun: 'yes'"
                  >Ki&#351;isel verilerin silinmesini veya yok edilmesini
                  isteme,</span
                ><span style="mso-spacerun: 'yes'"> </span>
              </p>
              <p
                class="46"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  margin-left: 36pt;
                  text-indent: -18pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                  mso-list: l3 level1 lfo14;
                "
              >
                <span
                  style="
                    font-family: Symbol;
                    mso-fareast-
                    mso-bidi-
                    
                  "
                  ><span style="mso-list: Ignore"
                    >&#183;<span>&nbsp;</span></span
                  ></span
                >
                <span style="mso-spacerun: 'yes'"
                  >Ki&#351;isel verilerin d&#252;zeltilmesi, silinmesi ya da yok
                  edilmesi halinde bu i&#351;lemlerin ki&#351;isel verilerin
                  aktar&#305;ld&#305;&#287;&#305; &#252;&#231;&#252;nc&#252;
                  ki&#351;ilere bildirilmesini isteme,</span
                ><span style="mso-spacerun: 'yes'"> </span>
              </p>
              <p
                class="46"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  margin-left: 36pt;
                  text-indent: -18pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                  mso-list: l3 level1 lfo14;
                "
              >
                <span
                  style="
                    font-family: Symbol;
                    mso-fareast-
                    mso-bidi-
                    
                  "
                  ><span style="mso-list: Ignore"
                    >&#183;<span>&nbsp;</span></span
                  ></span
                >
                <span style="mso-spacerun: 'yes'"
                  >&#304;&#351;lenen ki&#351;isel verilerin m&#252;nhas&#305;ran
                  otomatik sistemler vas&#305;tas&#305;yla analiz edilmesi
                  suretiyle veri sahibinin aleyhine bir sonucun ortaya
                  &#231;&#305;kmas&#305;na itiraz etme,</span
                ><span style="mso-spacerun: 'yes'"> </span>
              </p>
              <p
                class="46"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  margin-left: 36pt;
                  text-indent: -18pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                  mso-list: l3 level1 lfo14;
                "
              >
                <span
                  style="
                    font-family: Symbol;
                    mso-fareast-
                    mso-bidi-
                    
                  "
                  ><span style="mso-list: Ignore"
                    >&#183;<span>&nbsp;</span></span
                  ></span
                >
                <span style="mso-spacerun: 'yes'"
                  >Ki&#351;isel verilerin kanuna ayk&#305;r&#305; olarak
                  i&#351;lenmesi sebebiyle zarara u&#287;ramas&#305;
                  h&#226;linde zarar&#305;n giderilmesini talep etme.</span
                ><span style="mso-spacerun: 'yes'"> </span>
              </p>
              <p
                class="46"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  margin-left: 36pt;
                  text-indent: -18pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                  mso-list: l13 level1 lfo1;
                "
              >
                <span style="font-weight: bold"
                  ><span style="mso-list: Ignore"
                    >5.<span>&nbsp;</span></span
                  ></span
                >
                <b
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                    >Hak ve Talepleriniz &#304;&#231;in Bizimle
                    &#304;leti&#351;ime Ge&#231;mek &#304;sterseniz
                  </span></b
                ><b
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      font-weight: bold;
                    "
                  >
                  </span
                ></b>
              </p>
              <p
                class="p"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                "
              >
                <span
                  style="
                    mso-spacerun: 'yes';

                    mso-fareast-font-family: Calibri;
                  "
                  >Yukar&#305;da belirtilen haklar kapsam&#305;nda ilgili
                  taleplerinizi, “Veri Sorumlusuna Ba&#351;vuru Usul ve
                  Esaslar&#305; Hakk&#305;nda Tebli&#287;e” g&#246;re </span
                ><span
                  style="
                    mso-spacerun: 'yes';

                    mso-fareast-font-family: Calibri;
                  "
                >
                </span>
              </p>
              <p
                class="MsoNormal"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  margin-left: 21.3pt;
                  text-indent: -18pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                  mso-list: l5 level1 lfo15;
                "
              >
                <span
                  style="
                    font-family: Symbol;
                    mso-fareast-font-family: Calibri;
                    mso-bidi-
                    font-size: 10pt;
                  "
                  ><span style="mso-list: Ignore"
                    >&#183;<span>&nbsp;</span></span
                  ></span
                >
                <span
                  style="
                    mso-spacerun: 'yes';

                    mso-fareast-font-family: Calibri;
                  "
                  >Kimlik teyidinin yap&#305;lmas&#305; i&#231;in gerekli
                  resm&#238; belgeleri ekleyerek bizzat dilek&#231;e veya noter
                  kanal&#305; ile “</span
                ><b
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      mso-fareast-font-family: Calibri;
                      font-weight: bold;

                      background: rgb(255, 255, 255);
                      mso-shading: rgb(255, 255, 255);
                    "
                    >S&#246;&#287;&#252;t&#246;z&#252; Mah. 2176. Sk. No:63
                    06530&nbsp;&#199;ankaya / ANKARA</span
                  ></b
                ><span
                  style="
                    mso-spacerun: 'yes';

                    mso-fareast-font-family: Calibri;
                  "
                  >” adresinde yer alan merkezimize yaz&#305;l&#305; olarak
                  taraf&#305;m&#305;za iletebilirsiniz.</span
                ><span
                  style="
                    mso-spacerun: 'yes';

                    mso-fareast-font-family: Calibri;
                  "
                >
                </span>
              </p>

              <p
                class="p"
                align="justify"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  text-align: justify;
                  text-justify: inter-ideograph;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                "
              >
                <span
                  style="
                    mso-spacerun: 'yes';

                    mso-fareast-font-family: Calibri;
                  "
                  >Bu kapsamda yap&#305;lacak olan &#351;ahsen ba&#351;vurular
                  taraf&#305;m&#305;zdan yap&#305;lacak kimlik
                  do&#287;rulamas&#305;n&#305; takiben kabul edilecek olup
                  talepleriniz, niteli&#287;ine g&#246;re en k&#305;sa
                  s&#252;rede ve en ge&#231; 30 g&#252;n i&#231;inde
                  sonu&#231;land&#305;r&#305;lacakt&#305;r. Ba&#351;vuruya
                  yaz&#305;l&#305; olarak cevap verilmesi durumunda, 10 sayfaya
                  kadar &#252;cret al&#305;nmayacak olup 10 sayfan&#305;n
                  &#252;zerindeki her sayfa i&#231;in Veri Sorumlusuna
                  Ba&#351;vuru Usul ve Esaslar&#305; Hakk&#305;nda
                  Tebli&#287;’in 7. maddesinde yer alan i&#351;lem &#252;creti
                  al&#305;nabilecektir. Ba&#351;vuruya cevab&#305;n CD, flash
                  bellek gibi bir kay&#305;t ortam&#305;nda verilmesi halinde
                  ise kay&#305;t ortam&#305;n&#305;n maliyeti tutar&#305;nda bir
                  &#252;cret talep edilebilecektir.</span
                ><span
                  style="
                    mso-spacerun: 'yes';

                    mso-fareast-font-family: Calibri;
                  "
                >
                </span>
              </p>
              <p
                class="p"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  text-align: left;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                "
              >
                <b
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      mso-fareast-font-family: Calibri;
                      font-weight: bold;
                    "
                    >VER&#304; SORUMLUSU</span
                  ></b
                ><b
                  ><span
                    style="
                      mso-spacerun: 'yes';

                      mso-fareast-font-family: Calibri;
                      font-weight: bold;
                    "
                  >
                  </span
                ></b>
              </p>
              <p
                class="p"
                style="
                  margin-top: 6pt;
                  margin-bottom: 6pt;
                  text-align: left;
                  line-height: 114%;
                  background: rgb(255, 255, 255);
                "
              >
                <span
                  style="
                    mso-spacerun: 'yes';

                    mso-fareast-font-family: Calibri;
                  "
                  >T&#220;RK&#304;YE CUMHUR&#304;YET&#304; T&#304;CARET
                  BAKANLI&#286;I</span
                ><span
                  style="
                    mso-spacerun: 'yes';

                    mso-fareast-font-family: Calibri;
                  "
                >
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AydinlatmaKurumsal",
};
</script>

<style scoped>
html,
body {
  scroll-padding-top: 100px;
}

h1,
h1 * {
  font-size: 1.4rem !important;
}
h2,
h2 * {
  font-size: 1.2rem !important;
  text-align: left;
  margin-bottom: 0.6vh;
  padding-bottom: 0.2vh;
}
h3,
h3 * {
  font-size: 1.1rem !important;
  text-align: left;
  margin-bottom: 0.2vh;
  padding-bottom: 0.2vh;
  color: #55565e;
}
h4,
h4 * {
  font-size: 1rem !important;
}
h5,
h5 * {
  font-size: 1rem !important;
  color: #55565e;
}

p > strong {
  display: inline-block;
  position: relative;
  text-indent: -16px;
}

p .staticpage .descline p {
  padding: 0 0 2vh 0;
}
.desctext p {
  margin-bottom: 0.6vh;
}

.desctext {
  display: block;
  width: 100%;
}

ol li,
ul li,
p {
  padding-bottom: 10px;
  line-height: 2;
}
ol li b {
  text-decoration: underline;
}

ul,
ol {
  margin: 10px 0px 15px 60px;
  text-align: left;
}

ul li {
  display: list-item;
  list-style: disc !important;
  text-align: left;
}

.accordion {
  position: relative;
  margin: 1vh 0;
  display: block;
  width: 100%;
  transition: all 0.3s;
}
.accordion * {
  transition: all 0.3s;
}
.accordion li {
  display: block;
  list-style: circle;
  margin: 1vh 5vh;
}
summary {
  font-weight: 500;
  cursor: pointer;
  padding: 2vh;
  margin: 0;
  color: #4da0df;
  outline: none;
  display: flex;
  align-items: center;
}

summary:hover {
  background: #fdfbfa;
}

details * {
  transition: all 0.3s ease-out;
}

details p {
  padding: 2vh !important;
  border-bottom: 1px solid #eee;
}

@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {
  summary {
    display: flex;
    flex-direction: row;
    padding: 4vw;
    align-items: center;
    justify-content: flex-start;
  }
  details summary::-webkit-details-marker {
    display: none;
  }
  table {
    width: 500px;
  }
}
</style>
