<template>
  <div :class="{ notvalid: hataMesaji }">
    <div :class="{ notvalid: message }">
      <div class="toollabel">
        <label>Güvenlik Kodu</label>
        <tool-tip>Lütfen resimdeki kodu giriniz.</tool-tip>
      </div>
      <!-- <div class="desc"></div> -->
      <div class="captcha">
        <div class="captchaimg">
          <div ref="div_svg" v-html="captchaImg"></div>
        </div>
        <div class="btn captchabtn" @click="Get_New_Capcha">
          <a>
            <div>Yenile</div>
          </a>
        </div>
      </div>
      <input
        autocomplete="off"
        ref="code"
        type="text"
        v-model="captchaCode"
        @input="updateData"
        placeholder="Üstteki kodu giriniz"
        @keyup.enter="$emit('enter')"
      />
      <div class="hint">{{ message }}</div>
    </div>
    <div class="hint">{{ hataMesaji }}</div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "Capcha",
  data() {
    return {
      captchaImg: "",
      captchaCode: "",
      captchaId: "",
      counter: 120,
      hataMesaji: ""
    };
  },
  mounted() {
    this.Get_New_Capcha();
    this.$root.$on("reset_capcha", this.Get_New_Capcha);
  },

  props: ["value", "message", "section"],
  methods: {
    ...mapActions("applicant", ["get_new_captcha"]),
    focus() {
      this.$refs.code.focus();
    },
    Get_New_Capcha() {
      this.captchaImg = "";
      this.captchaId = "";
      this.captchaCode = "";
      this.updateData();
      this.get_new_captcha({
        section: this.section,
        label: "min"
      })
        .then(res => {
          if (res.ok) {
            this.captchaImg = res.payload.imageSvg;
            this.captchaId = res.payload.cid;
            this.counter = res.payload.ttl;
            this.$nextTick(() => {
              var div_sgv = this.$refs.div_svg;
              if (typeof div_sgv !== "undefined") {
                div_sgv.firstElementChild.setAttribute("height", "100%");
                div_sgv.firstElementChild.setAttribute("width", "100%");
              } else {
                this.$env.CONSOLE.log("WARNING: div_svg is undefined");
              }
              // _paq.push([
              //   "trackEvent",
              //   "Captcha",
              //   "Generate-" + this.section,
              //   "Success"
              // ]);
            });
            if (this.$env.MOCK) {
              this.captchaCode = res.debug.captchaText;
            }
            this.updateData();
          }
        })
        .catch(err => {
          // _paq.push([
          //   "trackEvent",
          //   "Captcha",
          //   "Generate-" + this.section,
          //   "Error"
          // ]);
          this.hataMesaji = err.message;
        });
    },
    updateData() {
      this.hataMesaji = "";
      this.$emit("input", {
        captchaId: this.captchaId,
        captchaCode: this.captchaCode.replace("_", "")
      });
    }
  }
};
</script>
<style scoped>
.counter {
  font-size: small;
  font-weight: 300;
}
.captchaimg > div {
  height: inherit;
}
/*  on big screens ********************************************************************************************/
/* @media only screen and (min-height: 1000px) {
} */
/*  on phones ********************************************************************************************/
/* @media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {
} */
/*  on tablets portrait ********************************************************************************************/
/* @media (orientation: portrait) and (min-width: 501px) {
} */
</style>
