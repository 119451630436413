<template>
  <div class="basvurunobox">

    <div class="NoHead">
      <label>Başvuru No</label>
      <tool-tip>Lütfen başvuru numaranızı kaydedin.
        <br />
        Bu numara ile başvurunuzu dilediğiniz zaman sürdürebilirsiniz.
      </tool-tip>
    </div>

    <div class="num"><slot></slot></div>
  </div>
</template>

<script>
export default {
  name: "BasvuruNoBox",

};
</script>

<style scoped>
</style>