<template>
  <div class="hizmet-sozlesmesi">
    <div>

        <h4>İleti Yönetim Sistemi A.Ş. <br> Hizmet Sağlayıcı Aydınlatma Metni </h4>

        <p><b>Veri sorumlusu</b> Bu Aydınlatma Metni, İleti Yönetim Sistemi (<b>“İYS”</b>) üzerinden işlenecek kişisel veriler açısından veri sorumlusu sıfatıyla hareket eden İleti Yönetim Sistemi A.Ş. (<b>“Kuruluş”</b>) tarafından hazırlanmıştır. </p>

<p>Eğer hizmet sağlayıcı olarak İYS ile ilgili süreçlerinizde kurumunuzu temsil edecek çalışanlarınıza ya da üçüncü kişilere ait verileri sistem üzerinden bizlerle paylaşmanız söz konusu ise, ilgili kişileri işbu metni sunarak aydınlatmanız gerekmektedir. </p>

<p>
    Veri işleme amaçları İYS’ye kayıt esnasında ve kaydın devamı süresince tarafımıza sizler veya sizlerin temsilcisi olduğunuz tüzel kişilikler tarafından sağlanan kimlik, kurum veya iletişim bilgileri gibi bilgiler; 

</p>    
<p>
    <ul>   
        <li>İYS’ye kayıtların gerçekleştirilmesi, </li>
        <li>İYS’nin kanuni işlevinin yerine getirilmesi; bu kapsamda alıcılar ile kurumunuz arasındaki iletişimin sağlanması, izin yönetim faaliyetlerinin yürütülmesi, iletilere ilişkin şikâyetlere dair tarafınızla iletişim kurulması, İYS üzerinden katma değerli hizmetlerin sunulması, </li>
        <li>Kuruluş tarafından İYS’nin işlevleriyle ilgili olarak tarafınızla iletişime geçilmesi, </li>
        <li>Kanuni yükümlülüklerin yerine getirilmesi ve bu kapsamda gerekli arşivleme faaliyetlerinin gerçekleştirilmesi amaçlarıyla Kuruluş tarafından işlenebilecektir. </li>
    </ul>
</p>


<p><b>Verilerin aktarıldığı taraflar ve aktarım sebepleri</b> İYS üzerinden bizlere sağlamış olduğunuz bilgileriniz, yukarıda yer alan amaçların gerçekleştirilmesi amacıyla iş ortaklarımıza ve tedarikçilerimize, yetkili kamu kurumları ve özel kuruluşlara ve iştiraklerimize aktarılabilecektir.</p> 

<p><b>Verilerin toplandığı kanallar ve kanuni veri işleme sebepleri</b> Kişisel verileriniz İYS kayıt esnasında elektronik ortamda e-posta, çevrim içi web arayüzleri ve fiziksel ortamda matbu formlar ve bizlere sağladığınız fiziksel dokümanlar üzerinden toplanabilmektedir. Söz konusu kişisel veriler, Kanunun 5'inci maddesinde belirtilen “veri sorumlusunun hukuki yükümlülüğünün yerine getirilmesi”, “sözleşmenin kurulması ve ifası” ile “veri sorumlusunun meşru menfaati” kanuni veri işleme şartlarına dayanılarak işlenebilecektir. </p>

<p>
    <b>Veri sahibi hakları</b> Kanunun “ilgili kişinin haklarını düzenleyen” 11'inci maddesi kapsamındaki taleplerinizi, Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğe göre Kuruluşumuza iletebilirsiniz. 
</p>          
      
  </div></div>
</template>

<script>
export default {
  name: "AydinlatmaMetni"
};
</script>

<style scoped>



</style>
