<template>
  <div :class="{notvalid: message}">
    <label>E-posta:</label>
    <input :class="{fixed}" type="text" name="email" v-model="email"
           @input="$emit('input', {str:email, validated})" />
    <div class="hint">{{message}}</div>
  </div>
</template>

<script>
import EmailValidator from "email-validator";
export default {
  name : "Email",
  props: {
    value: Object,
    message: String,
    fixed: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      email: ""
    };
  },

  watch: {
    value: {
      deep : true,
      handler (n) {
        this.email = n.str;
      }
    }
  },

  computed: {
    validated () {
      return EmailValidator.validate(this.email);
    }
  },

  mounted() {

    this.email = this.value.str;
  }
};
</script>

<style scoped>
.fixed {
  pointer-events: none;
  background-color: #f6f9f6;
  border: 0;
  color: #70a55f;
}
</style>
